const translationsHash = window.translations;

const I18n = {
  t: (key, params) => {
    const ymlKey = `frontend.${key.toLowerCase()}`;
    const translation = translationsHash[ymlKey];
    if (!translation) {
      return `Translation missing: ${ymlKey}`;
    }
    return I18n.translation_with_interpolations(translation, params);
  },

  translation_with_interpolations: (translation, params) => {
    const splitted = translation.toString().split(' ');
    const interpolations = splitted.filter((word) => word.startsWith('%{'));

    if (!interpolations.length) return translation;

    let result = translation;
    interpolations.forEach((i) => {
      const variable = i.substring(i.indexOf('{') + 1, i.lastIndexOf('}'));
      const value = params[variable];
      result = result.toString().replace(i, value);
    });

    return result;
  },

  days: () => I18n.t('date.day_names'),

  dayAbbreviations: () => I18n.t('date.abbr_day_names'),

  months: () => I18n.t('date.month_names'),

  monthAbbreviations: () => I18n.t('date.abbr_month_names')
};

export default I18n;
